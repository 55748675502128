// organisationApi.js

export const createOrganisation = async (token, organisation) => {
    console.log("creating organisation", organisation);
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}organisations/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(organisation),
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to create organisation: ${response.statusText} - ${errorDetails.message}`);
    }

    return await response.json();
};

export const fetchOrganisationDetails = async (token, organisationId) => {
    console.log("fetching organisation details", organisationId);
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}organisations/${organisationId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to fetch organisation details: ${response.statusText} - ${errorDetails.message}`);
    }

    return await response.json();
};
