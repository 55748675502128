import { useSelector } from "react-redux";
import * as atlas from 'azure-maps-control';
import * as turf from '@turf/turf'; // Import Turf.js
import { useState, useEffect, useRef } from 'react';
import mapConfig from '../config/mapConfig'; // Import mapConfig for subscription key

const useMap = () => {
    const project = useSelector((state) => state.project.selectedProject); 
    const mapData = project?.projectBoundary ? JSON.parse(project?.projectBoundary) : null;
    const [extraLocationData, setExtraLocationData] = useState({}); // Hold extra geographic data
    const lastFetchedCentroid = useRef(null); // Track last fetched centroid

    // Function to fetch extra data from Azure Maps Reverse Geocoding API
    const fetchLocationData = async (centroid) => {
        console.log("fetchLocationData for centroid", centroid);

        const [longitude, latitude] = centroid; // Centroid coordinates [longitude, latitude]
        const subscriptionKey = mapConfig.authOptions.subscriptionKey; // Fetch from mapConfig
        const endpoint = `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${subscriptionKey}&api-version=1.0&query=${latitude},${longitude}`;

        try {
            const response = await fetch(endpoint);
            const result = await response.json();
            if (result && result.addresses && result.addresses.length > 0) {
                const address = result.addresses[0].address;
                setExtraLocationData({
                    city: address.municipality,
                    county: address.municipalitySubdivision,
                    country: address.country,
                    state: address.countrySubdivision,
                });
            }
        } catch (error) {
            console.error("Error fetching location data from Azure Maps:", error);
        }
    };

    // Helper to get the bounding box from the stored GeoJSON
    const getPolygonDetails = () => {
        let obj = {
            Type: 'N/A',
            BoundingBox: null,
            BoundingBoxAsString: 'N/A',
            Centroid: null,
            CentroidAsString: 'N/A',
            Area: null,
            AreaAsString: 'N/A',
            Perimeter: null,
            PerimeterAsString: 'N/A',
            City: 'N/A',
            County: 'N/A',
            State: 'N/A',
            Country: 'N/A'
        };
    
        if (!mapData || !Array.isArray(mapData.features) || mapData.features.length === 0) {
            console.warn('Map data is null, undefined, or contains no features.');
            return obj; // Early return if mapData is invalid
        }
    
        const feature = mapData.features[0];
    
        if (!feature?.geometry) {
            console.warn('Feature does not have a valid geometry.');
            return obj; // Early return if the feature has no geometry
        }
    
        // Extract type of geometry
        obj.Type = feature?.geometry?.type || "Unknown type";
    
        if (mapData?.type === 'FeatureCollection') {
            try {
                const coordinates = feature?.geometry?.coordinates?.[0];
    
                // Check if coordinates is a valid array of at least 2 positions
                if (!Array.isArray(coordinates) || coordinates.length < 2) {
                    console.warn('Coordinates are invalid or not enough positions to create a lineString.');
                    return obj; // Return early as the coordinates are not valid
                }
    
                // Calculate the centroid
                let centroidFeature = null;
                try {
                    centroidFeature = turf.centroid(mapData);
                    obj.Centroid = centroidFeature?.geometry?.coordinates || 'N/A';
                    obj.CentroidAsString = obj.Centroid ? JSON.stringify(obj.Centroid) : 'N/A';
                } catch (error) {
                    console.error('Error calculating centroid:', error);
                }
    
                // Calculate the bounding box
                try {
                    obj.BoundingBox = atlas.data.BoundingBox.fromData(mapData);
                    obj.BoundingBoxAsString = obj.BoundingBox ? JSON.stringify(obj.BoundingBox) : 'N/A';
                } catch (error) {
                    console.error('Error calculating bounding box:', error);
                }
    
                // Calculate area of the polygon (if possible)
                try {
                    obj.Area = turf.area(mapData);
                    obj.AreaAsString = obj.Area ? `${obj.Area.toFixed(2)} square meters` : 'N/A';
                } catch (error) {
                    console.error('Error calculating area:', error);
                }
    
                // Calculate the perimeter of the polygon using a lineString (if possible)
                try {
                    const lineString = turf.lineString(coordinates);
                    obj.Perimeter = turf.length(lineString, { units: 'kilometers' });
                    obj.PerimeterAsString = obj.Perimeter ? `${obj.Perimeter.toFixed(2)} kilometers` : 'N/A';
                } catch (error) {
                    console.error('Error calculating perimeter:', error);
                }
    
            } catch (error) {
                console.error('Error processing map data:', error);
            }
        }
    
        // Add extra location info (City, County, State, Country) from Azure Maps API
        obj.City = extraLocationData?.city || 'N/A';
        obj.County = extraLocationData?.county || 'N/A';
        obj.State = extraLocationData?.state || 'N/A';
        obj.Country = extraLocationData?.country || 'N/A';
    
        return obj;
    };
    
    
    useEffect(() => {
        if (mapData && mapData.features.length > 0) {
            const centroidFeature = turf.centroid(mapData);
            const currentCentroid = centroidFeature.geometry.coordinates;

            // Check if the centroid has changed before fetching new data
            if (!lastFetchedCentroid.current || (lastFetchedCentroid.current[0] !== currentCentroid[0] || lastFetchedCentroid.current[1] !== currentCentroid[1])) {
                fetchLocationData(currentCentroid); // Fetch extra geographic data
                lastFetchedCentroid.current = currentCentroid; // Update the last fetched centroid
            }
        }
    }, [mapData]);

    return {
        getPolygonDetails,
    };
};

export default useMap;
