import React from "react";
import { Button } from "react-bootstrap";

export function FooterComponent() {
    return (
        <footer className="bg-primary text-white text-center py-4 mt-4">
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <Button href="/newsletter" variant="link-light">
                        Newsletter
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p className="mb-0">
                        &copy; 2024 Australis Energy. All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    </footer>
    );
}
