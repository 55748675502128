import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Button, Alert, Container, Card, Row, Col, Spinner } from "react-bootstrap";

const NewsletterSignup = () => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // Tracks if the form is being submitted

    // reCAPTCHA callback to set the captchaToken
    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!captchaToken) {
            setError("Please complete the CAPTCHA.");
            return;
        }

        if (email.trim() === "") {
            setError("Please enter a valid email address.");
            return;
        }

        // Show success message immediately and reset form
        setSubmitted(true);
        setEmail("");
        setError(null);
        setIsSubmitting(true); // Set loading state

        fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}p/subscription/subscribe`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_PUBLIC_ACCESS_SUBSCRIPTION_KEY
            },
            body: JSON.stringify({ email, captchaToken })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to subscribe. Please try again.");
                }
                // No need to parse the response, as we only care about success
            })
            .catch((error) => {
                setError(error.message);
            })
            .finally(() => {
                setIsSubmitting(false); // Remove loading state
            });
    };

    return (
        <Container fluid className="">
            <Row className="justify-content-center">
                <Col xs={12} lg={8}>
                    <Card className="stylish-card p-4">
                        <Card.Body>
                            <Row className="justify-content-center">
                                <Col xs={12} sm={10} md={8} lg={4}>
                                    {submitted ? (
                                        <Alert variant="success" className="text-center">
                                            Welcome aboard. We'll be in touch...
                                        </Alert>
                                    ) : (
                                        <>
                                            <h5>Sign up to our newsletter:</h5>
                                            <hr />
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Group controlId="formEmail">
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="text-center mb-3"
                                                    />
                                                </Form.Group>

                                                <div className="d-flex justify-content-center mb-3">
                                                    <ReCAPTCHA
                                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                        onChange={handleCaptchaChange}
                                                        size="normal"
                                                    />
                                                </div>

                                                <Button
                                                    variant="light"
                                                    type="submit"
                                                    className="custom-button w-100"
                                                    disabled={isSubmitting} // Disable button when loading
                                                >
                                                    {isSubmitting ? (
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="me-2"
                                                            />
                                                            Submitting...
                                                        </>
                                                    ) : (
                                                        "Subscribe"
                                                    )}
                                                </Button>
                                            </Form>
                                        </>
                                    )}
                                    {error && (
                                        <Alert variant="danger" className="mt-3 text-center">
                                            {error}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default NewsletterSignup;
