const mapConfig = {
    authOptions: {
        authType: 'subscriptionKey',
        subscriptionKey: process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY, 
    },
    center: [-2.5, 54.5],  
    zoom: 5,               
    view: 'Auto',         
    language: 'en-UK',
    style: 'satellite_road_labels' // Set the default map view to Hybrid (satellite with road labels)
};

export default mapConfig;
