// notificationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserNotifications } from '../../api/notificationApi'; // Import the API call

// Thunk to fetch notifications for the user
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (userId, { rejectWithValue }) => {
    try {
      const notifications = await fetchUserNotifications(userId); // Call the API to get notifications
      return notifications;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [], // Stores the list of notifications
    loading: false,    // Indicates if the notifications are being loaded
    error: null,       // Stores any error that occurs during the fetching process
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Save the error message
      });
  },
});

export default notificationSlice.reducer;
