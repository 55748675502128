import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import * as atlas from 'azure-maps-control';
import { kml as convertKML } from '@tmcw/togeojson';
import JSZip from 'jszip';
import mapConfig from '../../config/mapConfig';
import { Container, Alert, Button } from 'react-bootstrap';
import { useToken } from "../../context/TokenContext"; // Use TokenContext for token
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveProject } from '../../redux/slices/projectSlice'; // Import the thunks
import { saveReport } from '../../redux/slices/reportSlice'; // Import the thunks

const AzureMapComponent = (props) => {
    console.log("AzureMapComponent props", props);
    const project = props.project;
    const projectId = project?.id || null;
    const organisationId = project?.organisationId || null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [clickedPolygon, setClickedPolygon] = useState(null);
    const [map, setMap] = useState(null);
    const [datasource, setDatasource] = useState(null);
    const { token } = useToken(); // Access the token from context

    // Initialize the map
    const initializeMap = () => {
        if (mapRef.current) {
            const newMap = new atlas.Map(mapRef.current, mapConfig);
            newMap.events.add('ready', () => {
                const newDatasource = new atlas.source.DataSource();
                newMap.sources.add(newDatasource);
                addPolygonLayer(newMap, newDatasource);
                addEventListeners(newMap, newDatasource);
                setDatasource(newDatasource);
                setMap(newMap);
            });
            return newMap;
        }
        return null;
    };

    // Add polygon layer to visualize data
    const addPolygonLayer = (map, datasource) => {
        const polygonLayer = new atlas.layer.PolygonLayer(datasource, null, {
            fillColor: '#E32E2E',
            strokeColor: '#000000',
            strokeWidth: 2,
        });
        map.layers.add(polygonLayer);

        // Add click event listener for the polygon layer
        map.events.add('click', polygonLayer, (e) => {
            if (e.shapes && e.shapes.length > 0) {
                const polygonData = e.shapes[0];
                setClickedPolygon(polygonData);
                setIsButtonEnabled(true); // Enable the button when a polygon is clicked
            }
        });
    };

    // Render the button, always visible but may be disabled
    const renderButton = () => (
        <Button
            variant={isButtonEnabled ? 'secondary' : 'dark'}
            className="w-100"
            onClick={() => {
                if (isButtonEnabled) {
                    dispatch(saveReport({ reportData: { projectId, organisationId }, token }))
                        .unwrap()
                        .then((response) => {
                            // Navigate to the new report page with the reportId from the response
                            const reportId = response.reportId; // Adjust based on the response structure
                            navigate(`/organisations/${organisationId}/projects/${projectId}/reports/${reportId}`);
                        })
                        .catch((error) => {
                            console.error("Error saving report:", error);
                            // Optionally, you can handle the error state here
                        });
                }
            }}
            disabled={!isButtonEnabled} // Disable the button if not enabled
        >
            Run Report
        </Button>
    );

    // Add event listeners to the map
    const addEventListeners = (map, datasource) => {
        mapRef.current.addEventListener('dragover', handleDragOver);
        mapRef.current.addEventListener('drop', (event) => handleFileDrop(event, datasource, map));
    };

    // Handle drag-over event
    const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    };

    // Handle file drop event
    const handleFileDrop = async (event, datasource, map) => {
        event.preventDefault();
        datasource.clear();

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            const fileName = file.name.toLowerCase();

            if (fileName.endsWith('.geojson') || fileName.endsWith('.json')) {
                await handleGeoJSON(file, datasource, map);
            } else if (fileName.endsWith('.kml')) {
                await handleKML(file, datasource, map);
            } else if (fileName.endsWith('.kmz')) {
                await handleKMZ(file, datasource, map);
            } else {
                alert('Unsupported file format. Please upload a GeoJSON, JSON, KML, or KMZ file.');
            }
        }
    };

    // Handle GeoJSON and JSON files
    const handleGeoJSON = async (file, datasource, map) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const geojsonData = JSON.parse(e.target.result);

                console.log("Add geoJson to datasource", geojsonData);

                addDataToMap(datasource, map, geojsonData);
                await savePolygonDataWithToken(geojsonData);
            } catch (error) {
                alert('Invalid GeoJSON or JSON file.');
            }
        };
        reader.readAsText(file);
    };

    // Handle KML files
    const handleKML = async (file, datasource, map) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                // Step 1: Parse KML file content using DOMParser
                const parser = new DOMParser();
                const kmlDocument = parser.parseFromString(e.target.result, 'application/xml');
    
                // Check if parsing failed (sometimes "parseFromString" returns an error document)
                const parserError = kmlDocument.getElementsByTagName('parsererror');
                if (parserError.length > 0) {
                    console.error('Error parsing KML:', parserError[0].textContent);
                    alert('Invalid KML file format.');
                    return;
                }
    
                // Step 2: Convert KML to GeoJSON using @tmcw/togeojson
                const geoJson = convertKML(kmlDocument);
                
                if (!geoJson || !geoJson.type) {
                    console.error('Invalid GeoJSON from KML:', geoJson);
                    alert('Invalid KML file format. The conversion to GeoJSON failed.');
                    return;
                }
    
                console.log('Successfully converted KML to GeoJSON:', geoJson);
    
                // Step 3: Filter out features with null geometries if needed
                const validFeatures = geoJson.features.filter(feature => feature.geometry !== null);
    
                if (validFeatures.length === 0) {
                    console.warn('No valid geometries found in the KML file.');
                    alert('No valid geometries found in the KML file.');
                    return;
                }
    
                // Step 4: Add valid features to the map
                geoJson.features = validFeatures; // Update the GeoJSON to only include valid features
                addDataToMap(datasource, map, geoJson);
    
                // Step 5: Save the polygon data (optional)
                await savePolygonDataWithToken(geoJson);
            } catch (error) {
                console.error('Error processing KML file:', error);
                alert('Invalid KML file.');
            }
        };
        reader.readAsText(file); // Read file as text
    };
    


    // Handle KMZ files
    const handleKMZ = async (file, datasource, map) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const zip = await JSZip.loadAsync(e.target.result);
                const kmlFile = zip.file(/\.kml$/i)[0];
                if (kmlFile) {
                    const kmlText = await kmlFile.async('text');
                    const kmlDocument = new DOMParser().parseFromString(kmlText, 'application/xml');
                    const geoJson = convertKML(kmlDocument);

                    console.log("Add geoJson to datasource", geoJson);

                    addDataToMap(datasource, map, geoJson);
                    await savePolygonDataWithToken(geoJson);
                } else {
                    alert('Invalid KMZ file. No KML found.');
                }
            } catch (error) {
                alert('Error processing KMZ file.');
            }
        };
        reader.readAsArrayBuffer(file);
    };

    // Add data to the map and adjust the view
    const addDataToMap = (datasource, map, geojsonData) => {
        let parsedData;

        // Try parsing the GeoJSON data if it's a string
        try {
            parsedData = typeof geojsonData === 'string' ? JSON.parse(geojsonData) : geojsonData;
        } catch (error) {
            console.error('Failed to parse GeoJSON data:', error);
            return;
        }

        // Check if the parsed data is a valid GeoJSON object
        if (!parsedData || !parsedData.type) {
            console.error('Invalid GeoJSON data:', parsedData);
            return;
        }

        // Add the data to the datasource based on the type
        switch (parsedData.type) {
            case 'FeatureCollection':
                datasource.add(parsedData);
                break;
            case 'Feature':
                datasource.add(parsedData);
                break;
            case 'Polygon':
            case 'MultiPolygon':
            case 'LineString':
            case 'MultiLineString':
            case 'Point':
            case 'MultiPoint':
                datasource.add({
                    type: 'Feature',
                    geometry: parsedData,
                    properties: {}, // Optionally, add properties here if needed
                });
                break;
            default:
                console.error('Unsupported GeoJSON type:', parsedData.type);
                return;
        }

        // Adjust the map's view to fit the bounds of the data
        const bounds = atlas.data.BoundingBox.fromData(parsedData);
        if (bounds) {
            map.setCamera({ bounds, padding: 50 });
        }
    };


    // Save polygon data with token
    const savePolygonDataWithToken = async (geojsonData) => {
        try {
            await dispatch(saveProject({
                projectData: { ProjectId: projectId, OrganisationId: organisationId, polygonData: JSON.stringify(geojsonData) },
                token,
            }));
            setSuccessMessage("Polygon data saved successfully.");
        } catch (error) {
            setError(error.message);
        }
    };

    // Load project data and add to map if available
    useEffect(() => {
        const loadAndAddData = async () => {
            if (map && datasource) {
                try {
                    if (project.projectBoundary) {
                        addDataToMap(datasource, map, project.projectBoundary);
                    }
                } catch (error) {
                    setError('Failed to load project data');
                }
            }
        };
        loadAndAddData();
    }, [map, datasource, organisationId, projectId]); // Run when map, datasource, or IDs change

    // Initialize the map
    useLayoutEffect(() => {
        const map = initializeMap();
        return () => {
            if (map) {
                map.dispose();
                mapRef.current.removeEventListener('dragover', handleDragOver);
                mapRef.current.removeEventListener('drop', handleFileDrop);
            }
        };
    }, []);

    return (
        <Container>
            {renderButton()}
            {error && <Alert variant="danger">{error}</Alert>}
            <div ref={mapRef} className="azure-map-container" style={{ height: '700px', width: '100%' }}></div>
        </Container>
    );
};

export default AzureMapComponent;
