import React, { createContext, useState, useEffect, useContext } from 'react';
import { getToken as fetchNewToken } from '../utils/authUtils'; // Import the getToken function
import { useMsal } from "@azure/msal-react"; // Import MSAL instance from msal-react

export const TokenContext = createContext();

// Hook to access the token
export const useToken = () => {
    return useContext(TokenContext);
};

// Provider component to wrap the app and provide the token
export const TokenProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { instance } = useMsal(); // Get the MSAL instance from msal-react

    // Function to fetch a new token
    const fetchToken = async () => {
        setLoading(true);
        setError(null);
        try {
            const newToken = await fetchNewToken(instance); // Pass the MSAL instance to getToken
            if (!newToken) {
                console.warn("User is not authenticated");
                setToken(null); // Ensure token is null if no token is returned
            } else {
                console.log("New token acquired: ", newToken);
                setToken(newToken); // Set the token if it exists
            }
        } catch (err) {
            console.error("Error fetching token: ", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch the token when the app initializes
    useEffect(() => {
        if (!token) {
            fetchToken();
        }
    }, [token]);

    return (
        <TokenContext.Provider value={{ token, loading, error, fetchToken }}>
            {children}
        </TokenContext.Provider>
    );
};
