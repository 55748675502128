import React from "react";
import { Card, Container, Row, Col, Badge } from "react-bootstrap";
import { ActivityRings } from "@jonasdoesthings/react-activity-rings";

export function ReportScoreWheel() {
    return (
        <Container className="mb-4">
            <Card>
                <Card.Body>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <ActivityRings
                                rings={[
                                    { filledPercentage: 0.60, color: '#0d184f' },
                                    { filledPercentage: 0.96, color: '#25597e' },
                                    { filledPercentage: 0.87, color: '#64dacc' },
                                    { filledPercentage: 0.80, color: '#5245b0' },
                                ]}
                                options={{
                                    containerHeight: '20vh',
                                }}
                            />
                        </Col>
                        <Col>
                            <h1 className="mb-0"><Badge bg="secondary">55</Badge></h1>
                        </Col>
                        <Col sm="7">
                            <div >
                           
                                    The proximity of an Area of Outstanding Natural Beauty (AONB) may raise environmental and visual concerns.
                                    The location is also close to a designated country park, which could impact planning permissions due tential recreational and ecological disturbances.
                                    Additional assessments are needed to address potential shadowing effects from nearby natural features.
                               
                                <p>Further investigations and consultations with local authorities and environmental agencies are recommended to enhance the feasibility of this project.</p>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}