import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from "../../context/TokenContext";
import { useParams, useNavigate } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { ReportMap } from '../../components/report/ReportMap';
import { EnvironmentReports } from '../../components/report/EnvironmentReports';
import { ReportScoreWheel } from '../../components/report/ReportScoreWheel';
import { EventConsoleScreen } from '../../components/report/EventConsoleScreen';
import { getReport } from '../../redux/slices/reportSlice'; // Import the thunk action

const Report = () => {
    const { organisationId, projectId, reportId } = useParams(); // Get reportId from URL
    const { token, loading: tokenLoading, error: tokenError } = useToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Local state for the reportBoundary and environment data
    const [environmentData, setEnvironmentData] = useState([]);
    const [eventLog, setEventLog] = useState([]);
    const [reportScore, setReportScore] = useState(null);
    const [completeMessage, setCompleteMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [polygons, setPolygons] = useState([]);
    const [environmentReports, setEnvironmentReports] = useState([]);

    // Fetch the report from Redux
    const report = useSelector((state) => state.reports?.selectedReport);

    console.log("Report", report);

    const reportLoading = useSelector((state) => state.reports?.loading);
    const reportError = useSelector((state) => state.reports?.error);

    useEffect(() => {
        if (report?.projectBoundary) {
            setPolygons((prevState) => [
                ...prevState,
                { layerId: "projectBoundary", value: report?.projectBoundary, colour: "rgba(255, 0, 0, 0.5)", isBase64Encoded: false }
            ]);

        }
    }, [report]); // This runs only when `report` changes

    // Fetch the report when component mounts
    useEffect(() => {
        if (reportId && token) {
            setIsLoading(true); // Show loading spinner
            dispatch(getReport({ reportId, token }))
                .then(() => setIsLoading(false)) // Hide spinner after fetching
                .catch(() => setIsLoading(false)); // Ensure spinner is hidden on error too
        }
    }, [dispatch, reportId, token]);

    // SignalR connection setup to receive live updates
    useEffect(() => {
        console.log("SignalR Connection url", `${process.env.REACT_APP_API_BACKEND_URL}reportHub`);
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_BACKEND_URL}reportHub`)
            .configureLogging(signalR.LogLevel.Error)
            .build();

        console.log("SignalR Connection mapData_" + reportId);
        connection.on("calculationCompleteMessage_" + reportId, (data) => {
            debugger;
                console.log("CalculationCompleteMessage received", data);
                setCompleteMessage((prevState) => [...prevState, data]);
        });

        console.log("SignalR Connection mapData_" + reportId);
        connection.on("mapData_" + reportId, (data) => {
            if (data.type == "MapLayer") {
                console.log("mapData received", data);
                setPolygons((prevState) => [...prevState, { layerId: data.layerId, value: data.geoJson, colour: data.layerRGBValue, isBase64Encoded: true }]);
            }
        });

        console.log("SignalR Connection environmentReport_" + reportId);
        connection.on("environmentReport_" + reportId, (data) => {
            console.log("environmentReport received", data);
            setEnvironmentReports(data);
        });

        console.log("SignalR Connection eventLog_" + reportId);
        connection.on("eventLog_" + reportId, (data) => {
            console.log("eventLog received", data);
            setEventLog((prevState) => [...prevState, data]);
        });

        connection.start()
            .then(() => console.log("Connected to SignalR hub successfully."))
            .catch(err => console.error("SignalR Connection Error: ", err));

        return () => {
            connection.stop()
                .then(() => console.log("Disconnected from SignalR hub successfully."))
                .catch(err => console.error("Error disconnecting from SignalR hub: ", err));
        };
    }, [reportId]);

    // Handle loading and error states
    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (reportError) {
        return <p>Error loading report: {reportError}</p>;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Button variant="secondary" className='mb-4' href={`/organisations/${organisationId}/projects/${projectId}`} >Back to Project</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <Row>
                            <Col>
                                <ReportMap polygons={polygons} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                            <Col>
                                <EventConsoleScreen data={eventLog} />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col><ReportScoreWheel score={reportScore} /></Col>
                        </Row>
                        <Row>
                            <Col>
                                <EnvironmentReports data={environmentReports} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Report;
