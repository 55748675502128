import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';

export function EventConsoleScreen({ data }) {
    const [logData, setLogData] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            const newLogs = data.filter(
                (newItem) => !logData.some((existingLog) => existingLog.timeStamp === newItem.timeStamp)
            );
            setLogData((prev) => [...prev, ...newLogs]);
        }
    }, [data]);

    const formatTime = (timeStamp) => {
        const date = new Date(timeStamp);
        return date.toLocaleTimeString(); // Show only the time
    };

    return (
        <Card className="custom-card mt-4" style={{  maxHeight: '300px', overflowY: 'auto', }}>
            <Card.Body>
                {logData.length > 0 ? (
                    logData.map((item, i) => (
                        <div key={i} className="mb-1">
                            <small className="text-muted">{formatTime(item.timeStamp)}</small>
                            <small className="text-muted">{" "}-{" "}</small>
                            <Typewriter
                                words={[item.message]} // Typing out the message
                                typeSpeed={50}
                            />
                            <br />
                        </div>
                    ))
                ) : (
                    <div className="text-muted">No events to display.</div>
                )}
            </Card.Body>
        </Card>
    );
}
