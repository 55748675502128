import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import useApp from "./hooks/useApp";
import { TokenProvider } from './context/TokenContext'; // Import the TokenProvider
import CookieConsentManager from "./components/CookieConsentManager";

function App() {
    return (
        <Router>
            <TokenProvider>
                <AppContent />
            </TokenProvider>
        </Router>
    );
}

function AppContent() {

    const { WarmUp } = useApp();

    useEffect(() => {
        WarmUp();
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CookieConsentManager />
            <AppRoutes />
        </div>
    );
}

export default App;
