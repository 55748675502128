import React from "react";
import { Container } from "react-bootstrap";

const Unauthorized = () => {
    return (
        <Container className="mt-4">
            <h2>Session Expired</h2>
            <p>
                Please log in to start creating and managing your energy projects.
                If you're new here, start by creating an account to begin your journey with us.
            </p>
        </Container>
    );
};

export default Unauthorized;
