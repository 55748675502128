import * as atlas from 'azure-maps-control';

export const setCameraView = (map, polygonDatasource) => {
    const features = polygonDatasource.getShapes();
    if (features.length > 0) {
        const boundingBox = atlas.data.BoundingBox.fromData(features);
        if (boundingBox) {
            map.setCamera({
                bounds: boundingBox,
                padding: 50
            });
        }
    }
};

export const getCentroidByLayerId = (layerId, polygonDatasource) => {
    // Get the shape by layerId
    const shapes = polygonDatasource.getShapes();
    
    // Find the shape matching the given layerId
    const shape = shapes.find(s => s.getId() === layerId);
    
    if (shape) {
        const geometry = shape.toJson().geometry;

        // Check if the geometry is a Polygon and compute centroid
        if (geometry && geometry.type === 'Polygon') {
            const coordinates = geometry.coordinates[0]; // Get the outer ring coordinates
            const centroid = calculatePolygonCentroid(coordinates);
            return centroid;
        }
    }
    return null;
};

export const calculatePolygonCentroid = (coordinates) => {
    let xSum = 0, ySum = 0, totalPoints = coordinates.length;
    
    coordinates.forEach(coord => {
        xSum += coord[0]; // X coordinate (longitude)
        ySum += coord[1]; // Y coordinate (latitude)
    });
    
    const centroidX = xSum / totalPoints;
    const centroidY = ySum / totalPoints;
    
    return [centroidX, centroidY]; // Return the centroid as an [x, y] array
};

export const drawLine = (pointA, pointB, lineDatasource, map) => {
    try {
        const line = new atlas.data.LineString([pointA, pointB]);
        const lineFeature = new atlas.data.Feature(line);

        lineDatasource.add(lineFeature);

        const lineLayer = new atlas.layer.LineLayer(lineDatasource, null, {
            strokeColor: '#000000',
            strokeWidth: 2,
            strokeOpacity: 0.3,
            strokeLineJoin: 'round',
            strokeDashArray: [1, 4],       // Dash pattern: 4 pixels dash, 2 pixels gap
        });

        map.layers.add(lineLayer);
    } catch (error) {
        console.error("Error drawing line:", error);
    }
};

export const addLayer = (data, map, polygonDatasource) => {
    try {
        const layerId = data.layerId;
        const isProjectBoundary = layerId == "projectBoundary";

        let geoJsonObject;      

        if(isProjectBoundary)
            geoJsonObject = JSON.parse(data.value);
        else
            geoJsonObject = JSON.parse(atob(data.value));

        if (!map || !map.layers) {
            console.warn("Map or layers are missing.");
            return;
        }

        const existingLayers = map.layers.getLayers();
        if (existingLayers.some(layer => layer.getId() === data.layerId)) {
            return;
        }

        if (geoJsonObject && geoJsonObject.type === 'FeatureCollection') {
            geoJsonObject.features.forEach((feature, index) => {
                feature.id = `${layerId}-${index}`;  // Add layerId to each feature
            });
            polygonDatasource.add(geoJsonObject);
        } else {
            console.error("Invalid GeoJSON structure", geoJsonObject);
            return;
        }

        const polygonLayer = new atlas.layer.PolygonLayer(polygonDatasource, data.layerId, {
            fillColor: data.colour,
            strokeWidth: 2,
            strokeOpacity: 0.8
        });

        map.layers.add(polygonLayer, 'labels');
    } catch (error) {
        console.error(`Error processing GeoJSON for layer: ${data.layerId}`, error);
    }
};