// useOrganisation.js
import { useDispatch } from "react-redux";
import { createNewOrganisation, fetchOrganisation } from "../redux/slices/organisationSlice";

const useOrganisation = () => {
  const dispatch = useDispatch();

  // Create an organisation using Redux thunk
  const create = async (organisation, token) => {
    return dispatch(createNewOrganisation({ token, organisation })).unwrap();
  };

  // Fetch organisation details using Redux thunk
  const fetchDetails = async (organisationId, token) => {
    return dispatch(fetchOrganisation({ token, organisationId })).unwrap();
  };

  return {
    create,
    fetchDetails,
  };
};

export default useOrganisation;
