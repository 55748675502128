const useApp = () => {
    const WarmUp = () => {
        fetch(
            `${process.env.REACT_APP_API_BACKEND_APIM_URL}app/warmup`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_PUBLIC_ACCESS_SUBSCRIPTION_KEY,                    
                }
            }
        ).then((response) => {
            if (response.ok) {
                console.log("Backend warmed up successfully.");
            } else {
                console.error("Failed to warm up backend:", response.statusText);
            }
        })
    };

    return {
        WarmUp
    };
};

export default useApp;
