import { PublicClientApplication } from "@azure/msal-browser";

// Replace these values with your Azure AD B2C details
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES],
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // You can also use localStorage
    storeAuthStateInCookie: false, // Set this to "true" if facing issues on IE11 or Edge
  }
};


// Create an instance of PublicClientApplication
const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
