import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AppLandingPage from './AppLandingPage';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './config/authConfig';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { isLandingPage } from './utils/domainUtils';

import './App.css';
import './styles/variables.scss';
import './styles/custom.scss';
import '../node_modules/swiper/swiper-bundle.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const loadLandingPage = isLandingPage();

msalInstance.initialize().then(() => {
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <MsalProvider instance={msalInstance}>
                        {loadLandingPage ? <AppLandingPage /> : <App />}

                    </MsalProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    );
});

reportWebVitals();
