import React from "react";
import { useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useUserAccount from "../hooks/useUserAccount"; // To check if the user is authenticated

const HomePage = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useUserAccount(); // Hook to check authentication
    const userData = useSelector((state) => state.user.userData); // Get user data from Redux

    const handleCreateOrganisation = () => {
        navigate("/organisations/create"); // Navigate to organisation creation page
    };

    return (
        <Container className="mt-4">
            <h2>Welcome to Australis</h2>
            <p>
                Please log in to start creating and managing your energy projects.
                If you're new here, start by creating an account to begin your journey with us.
            </p>

            {/* Show the "Create Organisation" button only if the user is logged in and organisationId is null */}
            {isAuthenticated() && userData && userData.organisationId == null && (
                <Button variant="primary" onClick={handleCreateOrganisation}>
                    Create Organisation
                </Button>
            )}
        </Container>
    );
};

export default HomePage;
