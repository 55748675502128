import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Container } from "react-bootstrap";
import AppRoutes from "./routes";
import { FooterComponent } from "./components/layout/FooterComponent";
import { TopHeader } from "./components/layout/TopHeader";
import useApp from "./hooks/useApp";
import { TokenProvider } from './context/TokenContext'; // Import the TokenProvider
import CookieConsentManager from "./components/CookieConsentManager";

function App() {
    return (
        <Router>
            <TokenProvider>
                <AppContent />
            </TokenProvider>
        </Router>
    );
}

function AppContent() {
    const { WarmUp } = useApp();

    useEffect(() => {
        WarmUp();
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <>
                <CookieConsentManager />
                <TopHeader />
                <Container fluid className="flex-grow-1 mt-3">
                    <AppRoutes />
                </Container>
                <FooterComponent /></>
        </div>
    );
}

export default App;
