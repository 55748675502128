import React from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import "../styles/cookieBanner.css";

const CookieConsentManager = () => {
  const policyHTML = `
  <div class="row">
    <div class="col">
    <p>Australis Energy ("we," "our," or "us") is committed to ensuring that your privacy is protected. This Cookie Policy explains what cookies are, how we use them on our website (<a href='https://australis.energy'>https://australis.energy</a>), and how you can manage your cookie preferences.</p>
    <h3>What Are Cookies?</h3>
    <p>Cookies are small text files placed on your device (computer, smartphone, or other electronic device) when you visit a website. They are widely used to make websites work more efficiently and provide information to the website's owners.</p>
    
    <h3>Contact Us</h3>
    <p>If you have any questions about our use of cookies, please contact us at:<br/>Email: <a href='https://mailto:info@australis.energy'>info@australis.energy</a><br/>Address: [Insert Business Address]</p>

    </div>
    <div class="col">

<h3>How We Use Cookies</h3>
    <ul>
      <li>Ensure Proper Functioning of Our Website</li>
      <li>Enhance User Experience</li>
      <li>Analyze Website Usage</li>
      <li>Provide Secure Authentication</li>
    </ul>
    <h3>Managing Your Cookies</h3>
    <p>You can manage or disable cookies through your browser settings. Disabling certain cookies may affect the website's functionality.</p>
    
    </div>
  </div>
    
    
  `;

  const policyVersion = 1; // Update this number whenever the policy changes
  const currentVersion = parseInt(getCookieConsentValue("cookiePolicyVersion"), 10) || 0;

  return (
    <>
      {currentVersion !== policyVersion && (
        <CookieConsent
        onAccept={() => {
          document.cookie = `cookiePolicyVersion=${policyVersion}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        }}
        onDecline={() => {
          document.cookie = "cookiePolicyVersion=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        }}
        enableDeclineButton={true}
        buttonText="I Accept"
        declineButtonText="I Decline"
        containerClasses="cookie-banner"
        buttonClasses="cookie-banner-button"
        declineButtonClasses="cookie-banner-decline-button"
        contentClasses="cookie-banner-content"
      >
        <div dangerouslySetInnerHTML={{ __html: policyHTML }} />
      </CookieConsent>
      )}
    </>
  );
};

export default CookieConsentManager;
