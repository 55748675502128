import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveReport as apiSaveReport, getReport as apiGetReport } from '../../api/reportApi';

export const saveReport = createAsyncThunk(
    'reports/saveReport',
    async ({ reportData, token }, { rejectWithValue }) => {
        try {
            const report = await apiSaveReport(reportData, token);
            return report;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getReport = createAsyncThunk(
    'reports/getReport',
    async ({ reportId, token }, { rejectWithValue }) => {
        try {
            const report = await apiGetReport(reportId, token); 
            return report;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const reportSlice = createSlice({
    name: 'reports',
    initialState: {
        reports: [],       // If you're keeping a list of reports
        selectedReport: null,  // Holds the current report being viewed
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle saveReport
            .addCase(saveReport.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveReport.fulfilled, (state, action) => {
                state.loading = false;
                state.reports.push(action.payload); // Add the new report to the reports list
            })
            .addCase(saveReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            
            // Handle getReport
            .addCase(getReport.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.selectedReport = null; // Clear out selected report when loading
            })
            .addCase(getReport.fulfilled, (state, action) => {
                state.loading = false;
                state.selectedReport = action.payload; // Set the fetched report
            })
            .addCase(getReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default reportSlice.reducer;
