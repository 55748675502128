import React, { useEffect } from 'react';
import { Container, Card, Row, Col, Button, ListGroup, Badge, Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from '../../redux/slices/projectSlice';
import AzureMapComponent from "../../components/project/AzureMapComponent";
import MapDetails from "../../components/MapDetails";
import { useToken } from "../../context/TokenContext";
import { BoxArrowRight } from "react-bootstrap-icons";
import { useNavigate } from 'react-router-dom';

const ProjectDetails = () => {
    const { organisationId, projectId } = useParams();
    const dispatch = useDispatch();
    const { token, loading } = useToken();
    const navigate = useNavigate();

    const project = useSelector((state) => state.project.selectedProject);
    const error = useSelector((state) => state.project.error);


    useEffect(() => {
        if (!loading && token && organisationId && projectId) {
            dispatch(fetchProject({ projectId, token }));
        }
    }, [dispatch, organisationId, projectId, token, loading]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Container fluid>
         
            <Row>
                <Col><h4>{project?.name || "Project Details"}</h4></Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Card.Body style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            {project ? <AzureMapComponent project={project} /> : <></>}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>                   
                    <Row>
                        <Col xs={6}>
                            <MapDetails project={project} />
                        </Col>
                        <Col xs={6}>
                            <Card>
                                <Card.Header>Reports</Card.Header>
                                <Card.Body>
                                    <ListGroup>
                                        {project?.reports?.map((report, index) => (
                                            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <Button
                                                        variant="link"
                                                        className="p-0 me-2"
                                                        onClick={() => navigate(`/organisations/${project.organisationId}/projects/${project.id}/reports/${report.id}`)}
                                                    >
                                                        <BoxArrowRight />
                                                    </Button>
                                                    <div className="fw-bold me-2">{report.id.substring(0, 30)}....</div> {/* Trimmed report ID */}
                                                    <span className="text-muted">{report.author}</span>
                                                </div>
                                                <Badge bg="primary" pill>{report.score}/10</Badge>
                                            </ListGroup.Item>

                                        ))}
                                    </ListGroup>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default ProjectDetails;
