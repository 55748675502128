import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { useToken } from "../context/TokenContext"; 
import { saveUser, clearUser } from "../redux/slices/userSlice";
import { useNavigate } from 'react-router-dom';

const useUserAccount = () => {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const { fetchToken } = useToken(); // Access fetchToken from TokenContext
    const navigate = useNavigate();

    const login = async () => {
        try {
            console.log("Logging in...");
            const response = await instance.loginPopup({
                scopes: ["openid", "profile", "email"],
            });

            instance.setActiveAccount(response.account); // Set the active account in MSAL instance

            // Now that the user is logged in, fetch the token using TokenContext
            await fetchToken(); // Ensure TokenContext gets the token

            const userData = {
                oid: response.account.localAccountId,  // User's unique identifier
                name: response.account.name,          // User's display name
                email: response.account.username      // User's email
            };

            // Save user data via thunk (dispatch Redux action)
            var user = await dispatch(saveUser({ userData })).unwrap();

            if(user.organisationId)
                navigate(`/organisations/${user.organisationId}/dashboard`);
            else
                navigate(`/organisations/create`);

        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    //https://localhost:3000/organisations/59fda99a-8cd8-47b6-88ea-8a7a11380da0/projects
    //https://localhost:3000/organisations/5b84d2a8-8ae5-4902-873a-f2750869a2e2/projects

    const logout = async () => {
        console.log("Logging out...");
        dispatch(clearUser()); // Clear Redux state
        await instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
    };

    const isAuthenticated = () => {
        return instance.getActiveAccount() !== null;
    };

    return {
        login,
        logout,
        isAuthenticated
    };
};

export default useUserAccount;
