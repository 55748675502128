// notificationApi.js

export const fetchUserNotifications = async (userId) => {
    // This mock will simulate fetching notifications from an API.
    console.log(`Fetching notifications for user: ${userId}`);

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                { id: 1, title: 'Project A - Report Ready', message: 'The report for Project A is now available.' },
                { id: 2, title: 'Project B - New Task', message: 'A new task has been assigned to Project B.' }
            ]);
        }, 1000); // Simulate network delay
    });
};
