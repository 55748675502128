import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default to localStorage
import userReducer from './slices/userSlice';
import mapReducer from './slices/mapSlice'; // Import the mapSlice reducer
import projectReducer from './slices/projectSlice';
import notificationReducer  from './slices/notificationSlice';
import reportReducer from './slices/reportSlice'; // Import your slice reducer

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
    reducer: {
        user: persistedReducer,
        map: mapReducer, 
        project: projectReducer,
        notifications: notificationReducer,
        reports: reportReducer, 
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
                // Ignore paths in the state or action that contain non-serializable values
                ignoredPaths: ['register', 'rehydrate'],
            },
        }),
});

const persistor = persistStore(store);

export { store, persistor };
