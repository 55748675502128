import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

const PageNotFound = () => {
    return (
        <Container className="text-center mt-5">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>Sorry, the page you are looking for does not exist.</p>
            <Button as={Link} to="/" variant="primary">
                Go to Homepage
            </Button>
        </Container>
    );
};

export default PageNotFound;
