// projectApi.js
export const getProjects = async (organisationId, token) => {
    console.log("fetching projects", organisationId);
    if (!token) {
        throw new Error("No token provided");
    }
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}organisations/${organisationId}/projects`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`, // Pass the token as an argument
        },
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to fetch projects: ${response.status} - ${errorDetails.message}`);
    }

    return await response.json();
};

export const getProjectById = async (projectId, token) => {
    console.log("fetching project by id", projectId, token);
    if (!token) {
        throw new Error("No token provided");
    }
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}projects/${projectId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to fetch project: ${response.status} - ${errorDetails.message}`);
    }

    return await response.json();
};

export const createProject = async (projectData, token) => {
    console.log("creating project", projectData);
    if (!token) {
        throw new Error("No token provided");
    }
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}projects/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(projectData),
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to create project: ${response.status} - ${errorDetails.message}`);
    }

    return await response.json();
};

export const saveProject = async (projectData, token) => {
    console.log("saving project", projectData);
    if (!token) {
        throw new Error("No token provided");
    }
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}projects/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(projectData),
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to save project: ${response.status} - ${errorDetails.message}`);
    }

    return await response.json();
};
