import React, { useState, useEffect } from "react";
import { Accordion, Spinner, Container, ProgressBar, Badge } from "react-bootstrap";

export function EnvironmentReports({ data }) {
    const [environmentReports, setEnvironmentReports] = useState([]);

    // Handle new data from props
    useEffect(() => {
        if (!data || !data.environmentType) return;

        setEnvironmentReports(prevReports => {
            // Check if the message already exists for the same environmentType and state
            const existingIndex = prevReports.findIndex(report =>
                report.environmentType === data.environmentType
            );

            if (existingIndex > -1) {
                // Replace the report if it's the same environmentType with a different state
                if (prevReports[existingIndex].state !== data.state) {
                    const updatedReports = [...prevReports];
                    updatedReports[existingIndex] = data;
                    return updatedReports;
                }
                // If it's a duplicate (same environmentType, state, and message), don't add
                return prevReports;
            } else {
                // Add new report to the list
                return [...prevReports, data];
            }
        });
    }, [data]);

    return (
        <Container>
            <Accordion className="custom-accordion">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <span style={{ flex: 1 }}>Landscape Stats</span>
                            <ProgressBar variant="landscape" now={60} style={{ flex: 2, marginLeft: '1em', marginRight: '2em' }} />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Accordion className="custom-accordion">
                            {environmentReports.map((report, index) => (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <Accordion.Header>
                                        <span className="ms-2">{report.environmentTypeString}</span>
                                        {report.state === "Started" && <Spinner animation="border" size="sm" className="ms-2" />}
                                        {report.state === "Succeeded" && <> <span className="ms-2">Complete:  {report.score}</span></>}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Name: {report.name || "Unknown"}<br />
                                        Distance from boundary: {report.distanceInMeters || "N/A"}m<br />
                                        <small className="text-muted">
                                            {report.description || "No description available."}
                                        </small><br />
                                        <small className="text-muted">
                                            <a href={report.link || "#"} target="_blank" rel="noopener noreferrer">
                                                {report.name || "More information"}
                                            </a>
                                        </small>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <span style={{ flex: 1 }}>Environment Stats</span>
                            <ProgressBar variant="environment" now={96} style={{ flex: 2, marginLeft: '1em', marginRight: '2em' }} />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>

                                </Accordion.Header>
                                <Accordion.Body>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <span style={{ flex: 1 }}>Planning Stats</span>
                            <ProgressBar variant="planning" now={87} style={{ flex: 2, marginLeft: '1em', marginRight: '2em' }} />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>

                                </Accordion.Header>
                                <Accordion.Body>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <span style={{ flex: 1 }}>Other Stats</span>
                            <ProgressBar variant="other" now={80} style={{ flex: 2, marginLeft: '1em', marginRight: '2em' }} />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>

                                </Accordion.Header>
                                <Accordion.Body>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
        </Container>
    );
}
