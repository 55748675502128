import React from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import HomePage from "./pages/HomePage";
import PageNotFound404 from "./pages/404";
import Unauthorized from "./pages/401";
import CreateOrganisation from "./pages/organisation/OrganisationCreate";
import OrganisationDetails from "./pages/organisation/OrganisationDetails";
import Dashboard from "./pages/projects/Dashboard";
import ProjectDetails from "./pages/projects/ProjectDetails";
import Report from "./pages/projects/Report";
import { useMsal } from "@azure/msal-react"; // Import MSAL hooks
import Sandbox from "./pages/Sandbox";
import { isLandingPage } from './utils/domainUtils';

const AppRoutes = () => {

    const loadLandingPage = isLandingPage();

    return (
        <Routes>
            {loadLandingPage ? <Route path="/" element={<LandingPage />} /> : <Route path="/" element={<HomePage />} />}
            <Route path="/401" element={<Unauthorized />} />
            <Route path="/sandbox" element={<Sandbox />} />
            <Route path="/newsletter" element={<LandingPage />} />
            <Route element={<PrivateRoutes />}>
                <Route path="/organisations/create" element={<CreateOrganisation />} />
                <Route path="/organisations/:organisationId/details" element={<OrganisationDetails />} />
                <Route path="/organisations/:organisationId/dashboard" element={<Dashboard />} />
                <Route path="/organisations/:organisationId/projects/:projectId" element={<ProjectDetails />} />
                <Route path="/organisations/:organisationId/projects/:projectId/reports/:reportId" element={<Report />} />
                <Route path="404" element={<PageNotFound404 />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
        </Routes>
    );
};

const PrivateRoutes = () => {
    const { accounts } = useMsal(); // Use MSAL to check authentication status

    // Check if the user is authenticated
    const isAuthenticated = accounts && accounts.length > 0;

    // Conditionally render the protected routes or redirect to the home page
    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/401" replace />
    );
}

export default AppRoutes;
