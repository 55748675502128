// src/store/mapSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mapData: null, // This holds the polygon data
};

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setMap: (state, action) => {
            console.log("Saving map data:", action.payload);
            state.mapData = action.payload; // Update the map data in the state
        },
        clearMap: (state) => {
            state.mapData = null; // Clear map data
        },
    },
});

export const { setMap, clearMap } = mapSlice.actions;
export default mapSlice.reducer;
