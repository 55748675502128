import React from 'react';
import useMap from '../hooks/useMap';
import { Card, Spinner, Alert } from "react-bootstrap";
import { useSelector } from 'react-redux';

const MapDetails = () => {
    const project = useSelector((state) => state.project.selectedProject); 
    const loading = useSelector((state) => state.project.loading); // Check loading state from Redux
    const error = useSelector((state) => state.project.error); // Check error state from Redux

    const { getPolygonDetails } = useMap();
    const data = getPolygonDetails();

    // Handle loading state
    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    // Handle error state
    if (error) {
        return (
            <Alert variant="danger" className="text-center">
                <strong>Error: </strong> {error}
            </Alert>
        );
    }

    // Handle when no project data is available
    if (!project) {
        return (
            <Alert variant="warning" className="text-center">
                No project data available.
            </Alert>
        );
    }

    // Render the polygon details when loading is finished and no errors occurred
    return (
        <Card className="shadow-sm">
            <Card.Header>Polygon Information</Card.Header>
            <Card.Body>
                <strong>City: </strong>{data?.City}<br/>
                <strong>County: </strong> {data?.County}<br/>
                <strong>Country: </strong> {data?.Country}<br/>
                <br/>
                <p><strong>Type:</strong> <br/>{data?.Type || 'N/A'}</p>
                <p><strong>Bounding Box:</strong> <br/>{data?.BoundingBoxAsString || 'N/A'}</p>
                <p><strong>Centroid:</strong> <br/>{ data?.CentroidAsString || 'N/A'}</p>
                <p><strong>Area:</strong> <br/>{data?.AreaAsString || 'N/A'}</p>
                <p><strong>Perimeter:</strong> <br/>{data?.PerimeterAsString || 'N/A'}</p>
            </Card.Body>
        </Card>
    );
};

export default MapDetails;
