import React, { useState } from 'react';
import { Container } from "react-bootstrap";
import { useSpring, animated } from '@react-spring/web'
import ReactiveButton from 'reactive-button';

const Sandbox = () => {
    const [state, setState] = useState('idle');
    const [springs, api] = useSpring(() => ({
        from: { x: 0 },
    }))

    const handleClick = () => {
        api.start({
            from: {
                x: 0,
            },
            to: {
                x: 100,
            },
        })
    }

    const onClickHandler = () => {
        setState('loading');

        // send an HTTP request
        setTimeout(() => {
            setState('success');
        }, 2000);
    };

    return (
        <Container className="mt-4">
            <h2>Welcome to Australis</h2>

            <ReactiveButton
                buttonState={state}
                idleText="Submit"
                loadingText="Loading"
                successText="Done"
                onClick={onClickHandler}
            />
            <hr/>
            <animated.div
                onClick={handleClick}
                style={{
                    width: 80,
                    height: 80,
                    background: '#ff6d6d',
                    borderRadius: 8,
                    ...springs,
                }}
            />
        </Container>
    );
};

export default Sandbox;
