export const getToken = async (instance) => {
    const activeAccount = instance.getActiveAccount(); // Get the active account

    if (!activeAccount) {
        return null;
    }

    const request = {
        scopes: [`https://australisenergyb2corg.onmicrosoft.com/${process.env.REACT_APP_B2C_API_CLIENT_ID}/access_as_user`],
        account: activeAccount, // Use the active account for the request
    };

    try {
        const response = await instance.acquireTokenSilent(request);
        console.log("Successfully received token", response);
        return response.accessToken; // Return the access token
    } catch (error) {
        console.error("Token acquisition failed", error);

        // If interaction is required, fallback to acquireTokenPopup
        if (error.name === "InteractionRequiredAuthError") {
            try {
                const response = await instance.acquireTokenPopup(request);
                return response.accessToken;
            } catch (popupError) {
                console.error("Popup token acquisition failed", popupError);
                return null; // Return null if token acquisition fails through popup as well
            }
        }

        return null; // Return null if token acquisition fails silently
    }
};
