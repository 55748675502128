// src/utils/domainUtils.js

export const isPortalDomain = () => {
    const hostname = window.location.hostname;

    // Treat `portal.australis.energy` and `australis-energy-dev-app.azurewebsites.net` as portal
    return (
        hostname.startsWith("portal") ||
        hostname === "australis-energy-dev-app.azurewebsites.net"
    );
};

export const isLandingPage = () => {
    const hostname = window.location.hostname;
    const isLocalhost = hostname === "localhost";

    // Determine if LandingPage should load
    return (!isPortalDomain() && !isLocalhost) || window.location.pathname === "/newsletter";
};
