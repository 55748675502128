import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveUserData } from '../../api/userApi'; // Import the API function

// Thunk to save user data
export const saveUser = createAsyncThunk(
  'user/saveUser',
  async ({ token, userData }, { rejectWithValue }) => {
    try {
      const response = await saveUserData(token, userData);
      return response; // Returns saved user data if successful
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null,     // Stores user data
    loading: false,     // Indicates whether saving user data is in progress
    error: null,        // Stores any error that occurs during user data saving
  },
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload; // Set user data when logged in
    },
    clearUser: (state) => {
      state.userData = null; // Clear user data when logging out
    },
    updateUserOrganisationId: (state, action) => {
      if (state.userData) {
        state.userData.organisationId = action.payload; // Update organisationId in userData
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload; // Set user data after saving
      })
      .addCase(saveUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Capture the error
      });
  },
});

export const { setUser, clearUser, updateUserOrganisationId } = userSlice.actions; // Export actions
export default userSlice.reducer;
