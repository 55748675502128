import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import * as atlas from 'azure-maps-control';
import mapConfig from '../../config/mapConfig';
import { addLayer, setCameraView, getCentroidByLayerId, drawLine } from '../../helpers/mapHelpers';
import 'azure-maps-control/dist/atlas.min.css';

export function ReportMap(props) {
    const layerData = props.polygons;
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [polygonDatasource, setPolygonDatasource] = useState(null);
    const [lineDatasource, setLineDatasource] = useState(null);

    useEffect(() => {
        const loadAndAddData = async () => {
            if (layerData && map && polygonDatasource && lineDatasource) {
                // Add layers
                layerData.forEach(layer => addLayer(layer, map, polygonDatasource));

                // Set the camera view based on the layers
                setCameraView(map, polygonDatasource);

                // Get centroid of projectBoundary
                const projectBoundaryCentroid = getCentroidByLayerId("projectBoundary-0", polygonDatasource);

                // For each layer, if it's not the project boundary, draw a line to the project boundary centroid
                layerData.forEach(layer => {
                    if (layer.layerId !== "projectBoundary") {
                        const layerCentroid = getCentroidByLayerId(layer.layerId + "-0", polygonDatasource);
                        if (layerCentroid && projectBoundaryCentroid) {
                            drawLine(projectBoundaryCentroid, layerCentroid, lineDatasource, map);
                        }
                    }
                });
            }
        };
        loadAndAddData();
    }, [layerData, map, polygonDatasource, lineDatasource]);

    const initializeMap = () => {
        if (mapRef.current) {
            const newMap = new atlas.Map(mapRef.current, mapConfig);
            newMap.events.add('ready', () => {
                const newPolygonDatasource = new atlas.source.DataSource();
                const newLineDatasource = new atlas.source.DataSource();
                newMap.sources.add(newPolygonDatasource);
                newMap.sources.add(newLineDatasource);
                setPolygonDatasource(newPolygonDatasource);
                setLineDatasource(newLineDatasource);
                setMap(newMap);
            });
        }
    };

    useLayoutEffect(() => {
        initializeMap();
    }, []);

    return (
            <Card>
                <Card.Body>
                    <Container>
                        <div ref={mapRef} style={{ height: '500px', width: '100%' }}></div>
                    </Container>
                </Card.Body>
            </Card>
    );
}
