// organisationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createOrganisation, fetchOrganisationDetails } from '../../api/organisationApi';

// Thunk to create an organisation
export const createNewOrganisation = createAsyncThunk(
  'organisation/createNewOrganisation',
  async ({ token, organisation }, { rejectWithValue }) => {
    try {
      const response = await createOrganisation(token, organisation);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to fetch organisation details
export const fetchOrganisation = createAsyncThunk(
  'organisation/fetchOrganisation',
  async ({ token, organisationId }, { rejectWithValue }) => {
    try {
      const response = await fetchOrganisationDetails(token, organisationId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const organisationSlice = createSlice({
  name: 'organisation',
  initialState: {
    organisationData: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearOrganisation: (state) => {
      state.organisationData = null;
    },
  },
  extraReducers: (builder) => {
    // Handle create organisation
    builder
      .addCase(createNewOrganisation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewOrganisation.fulfilled, (state, action) => {
        state.loading = false;
        state.organisationData = action.payload;
      })
      .addCase(createNewOrganisation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle fetch organisation details
    builder
      .addCase(fetchOrganisation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrganisation.fulfilled, (state, action) => {
        state.loading = false;
        state.organisationData = action.payload;
      })
      .addCase(fetchOrganisation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearOrganisation } = organisationSlice.actions;
export default organisationSlice.reducer;
